<template>
  <div class="text-center section-with-image-and-items py-5">
    <div class="container text-center p-3">
      <div class="row text-left container">
        <div class="col-12">
          <h3 class="text-center">{{ props.title }}</h3>
          <p class="text-justify">{{ props.description1 }}</p>
          <p class="text-justify">{{ props.description2 }}</p>
          <h5 class="mt-5 text-center">{{ props.subTitle }}</h5>
          <p class="text-center mb-2">{{ props.description3 }}</p>
        </div>
        <div class="container col-6 ">
          <div class="container">
            <div id="accordion-items" class="accordion my-5">
              <div
                v-for="(item, index) in props.items"
                v-bind:key="index"
                class="card mb-2 border-0 rounded"
              >
                <div class="card-header border-0 bg-light p-3 pr-5">
                  <a
                    href="javascript: void(0);"
                    class="faq position-relative text-dark"
                    aria-expanded="true"
                    v-bind:aria-controls="`accordion-item-${index}`"
                    data-toggle="collapse"
                    v-bind:data-target="`#accordion-item-${index}`"
                  >
                    <h6 class="title mb-0">
                      {{ item.title }}
                    </h6>
                  </a>
                </div>
                <div
                  v-bind:id="`accordion-item-${index}`"
                  role="tabpanel"
                  class="collapse"
                >
                  <div class="card-body px-2 py-4">
                    <p class="text-muted mb-0 faq-ans">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container col-6 ">
          <div class="container">
            <div id="accordion-items" class="accordion my-5">
              <div
                v-for="(item, index) in props.items2"
                v-bind:key="index"
                class="card mb-2 border-0 rounded"
              >
                <div class="card-header border-0 bg-light p-3 pr-5">
                  <a
                    href="javascript: void(0);"
                    class="faq position-relative text-dark"
                    aria-expanded="true"
                    v-bind:aria-controls="`accordion-item-${index}`"
                    data-toggle="collapse"
                    v-bind:data-target="`#accordion-item-${index}`"
                  >
                    <h6 class="title mb-0">
                      {{ item.title }}
                    </h6>
                  </a>
                </div>
                <div
                  v-bind:id="`accordion-item-${index}`"
                  role="tabpanel"
                  class="collapse"
                >
                  <div class="card-body px-2 py-4">
                    <p class="text-muted mb-0 faq-ans">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-3">{{ props.info }}</p>
        <div class="mt-4 container col-6">
            <router-link to="/Exclusiones"><button class="boton btn btn-primary btn-lg"> PRINCIPALES EXCLUSIONES </button></router-link>
        </div>
        <div class="mt-4 container col-6">
             <router-link to="/planes"><button class="boton btn btn-primary btn-lg"> PLANES </button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    props: Object,
  },
};
</script>

<style>

.boton {
    width: 460px;
}

</style>